import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import React from "react"
import { Col, Row } from "react-bootstrap"
import { DefaultHeader } from "../../../components/elements"
import { PageCenter } from "../../../components/elements/wrappers"
import { BecomeMemberForm } from "../../../components/forms"

import { ModalX } from "../../../components/elements/Modal.jsx"
import { Page } from "../../../components/elements"

export default function CorporateAssociateList() {
    const { allFile } = useStaticQuery(
        graphql`
            {
                allFile(
                    filter: {
                        relativePath: { regex: "/ca_list/" }
                        extension: { regex: "/jpg|png/" }
                    }
                    sort: { fields: base, order: ASC }
                ) {
                    edges {
                        node {
                            base
                            childImageSharp {
                                gatsbyImageData(
                                    height: 70
                                    quality: 80
                                    placeholder: NONE
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        `,
    )
    return (
        <Page
            title="Corporate Associate List"
            description="EGI scientists collaborate with colleagues across academic, industry and government organizations globally to discover and improve scientific innovation and applied technologies."
        >
            <PageCenter bg="white">
                <Helmet>
                    <style type="text/css">
                        {`
                    @media only screen and (max-width: 600px) {
                        #ca-list{
                            padding: unset !important;
                        }
                    }
                    `}
                    </style>
                </Helmet>
                <DefaultHeader
                    title="Corporate Associate List"
                    subtitle="EGI scientists collaborate with colleagues across academic, industry and government organizations globally to discover and improve scientific innovation and applied technologies."
                    type="small"
                />
                <Row id="ca-list" className="px-5 pb-5 border-bottom">
                    <Col style={{ textAlign: "center" }}>
                        {allFile.edges.map(e => (
                            <GatsbyImage
                                image={getImage(e.node)}
                                key={e.node.base}
                                alt={e.node.base}
                                className="m-4 d-inline-block"
                            />
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center pt-5 m-4">
                        <ModalX
                            title="Reach out for more information on becoming a member."
                            button="Become a member"
                        >
                            <BecomeMemberForm />
                        </ModalX>
                    </Col>
                </Row>
            </PageCenter>
        </Page>
    )
}
